<template>
  <div class="add-server">
    <el-form ref="postForm" label-width="auto" label-position="right" :model="postData" :rules="rules">
      <el-form-item label="学员姓名" prop="drvStudentName">
        <el-input v-model.trim="postData.drvStudentName"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="drvStudentIdentityCard">
        <el-input v-model.trim="postData.drvStudentIdentityCard" maxlength="18"></el-input>
      </el-form-item>
      <!-- <el-form-item label="申请原因" prop="businessType">
        <el-radio-group v-model="postData.businessType">
          <el-radio label="A">初次申领</el-radio>
          <el-radio label="B">增驾</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="业务类型" prop="usableVehicleType">
        <el-select v-model="postData.usableVehicleType" @change="totalPriceChangeHandler">
          <el-option
            v-for="(item, index) in $dict.vehicleType"
            :key="index"
            :value="item.value"
            :label="item.value"></el-option>
        </el-select>
      </el-form-item>
      <div class="dialog-tips">
        确认后将从余额中扣除<span>{{ totalPrice }}元</span>会员服务费用。
      </div>
      <div class="admin-dialog-btns">
        <el-button type="primary" @click="submitHandler()">确认</el-button>
        <el-button @click="$emit('end')">取消</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import {schStudentAdd} from '@/api/index';
import Decimal from 'decimal.js';
export default {
  data() {
    return {
      loading: false,
      rules: {
        drvStudentName: [this.$rules.required, this.$rules.peopleName()],
        drvStudentIdentityCard: [this.$rules.required, this.$rules.length({min: 18})],
        businessType: [this.$rules.required],
        usableVehicleType: [this.$rules.required],
        policyContractType: [this.$rules.required],
      },
      postData: {
        drvStudentName: '',
        drvStudentIdentityCard: '',
        businessType: '',
        usableVehicleType: '',
        policyContractType: '',
      },
      checkList: [],
      totalPrice: 0,
    };
  },
  created() {
    if (this.$store.state.template.templateType != 2) {
      this.checkList.push(1);
      this.postData.policyContractType = 1;
    }
  },
  methods: {
    totalPriceChangeHandler() {
      if (this.checkList.length && this.postData.usableVehicleType) {
        var templatePrice1 = 0;
        var templatePrice2 = 0;
        this.$store.state.template.templateData.forEach((item) => {
          if (item.templateType == 2) {
            templatePrice2 = JSON.parse(item.templateCode).income;
          } else if (item.templateType == 1) {
            const obj = JSON.parse(item.templateCode);
            obj.sub1.forEach((product1) => {
              if (product1.vehicleType == this.postData.usableVehicleType) {
                templatePrice1 = Decimal(templatePrice1).add(product1.income).toFixed(0);
              }
            });
            obj.sub2.forEach((product2) => {
              if (product2.vehicleType == this.postData.usableVehicleType) {
                templatePrice1 = Decimal(templatePrice1).add(product2.income).toFixed(0);
              }
            });
            obj.sub3.forEach((product3) => {
              if (product3.vehicleType == this.postData.usableVehicleType) {
                templatePrice1 = Decimal(templatePrice1).add(product3.income).toFixed(0);
              }
            });
          }
        });
        this.totalPrice = 0;
        if (this.checkList.indexOf(1) != -1) {
          this.totalPrice = Decimal(this.totalPrice).add(templatePrice1).toFixed(0);
        }
        if (this.checkList.indexOf(2) != -1) {
          this.totalPrice = Decimal(this.totalPrice).add(templatePrice2).toFixed(0);
        }
      }
    },

    checkboxChangeHandler() {
      if (this.checkList.length > 1) {
        this.postData.policyContractType = 3;
      } else {
        this.postData.policyContractType = this.checkList[0];
      }
      this.totalPriceChangeHandler();
    },

    submitHandler() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          this.loading = true;
          let postData = {};
          Object.assign(postData, this.postData);
          this.$utils.resolveRequest(schStudentAdd(postData), this);
        } else {
          this.$message.error('请填写正确信息！');
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.add-server {
  .dialog-tips {
    span {
      color: $--color-danger;
    }
    font-size: 18px;
    margin-bottom: 15px;
  }
}
</style>